import * as React from "react";

interface NavBarContextProps {
    currentRoute?: string;
}

const NavBarContext = React.createContext<NavBarContextProps>({});

export default NavBarContext;
export { NavBarContextProps };
