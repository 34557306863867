import { History } from "history";

export const ComponentRoute = ({name, version, tab}: {name: string, version?: string, tab?: string}) => {
    const href = `/component/${[name, version, tab].filter(x => !!x).join("/")}`;
    const location: History.LocationDescriptor = {
        pathname: href,
    };

    return {
        href,
        location,
        go: (history: History<any>) => history.push(href),
    };
};
