
import { ComponentVersionHeader } from "@technologyone/t1-dxp-component-designsystem";
import { ComponentVersion } from "../..";

const V0Modules = {
    designsystem: { type: "dxp-kit.code.one/dxp-component/definition", location: "designsystem.js" }
};

export const ConvertComponentVersion: (versionHeader: ComponentVersionHeader, opts: {componentBaseUrl?: string}) => ComponentVersion =
(versionHeader, {componentBaseUrl} = {}) => {
    const response: ComponentVersionHeader = { modules: { } };

    Object.entries(versionHeader?.modules || V0Modules)
        .forEach(([moduleId, moduleInfo]) => response.modules = {
            ...response.modules,
            [moduleId]: {...moduleInfo, location: getModuleUrl(moduleInfo.location, componentBaseUrl)}
        });

    return response;
};

const getModuleUrl = (moduleLocation: string, componentBaseUrl?: string): string => {
    if (!componentBaseUrl) return moduleLocation;
    const baseUrl = new URL(componentBaseUrl, window.location.href).href;
    return new URL(moduleLocation, baseUrl).href;
};
