import { combineEpics, Epic } from "redux-observable";
import { filter, mergeMap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";

import { FetchComponentIndexAsync } from "../component";
import { ChannelIndex, GetChannelFromVersion, UpdateChannels } from "../channels";

import { UpdateThemes } from "./themeActions";
import { IsModuleTheme, ThemeIndex } from "./themeState";

const componentIndexChannelIntegration: Epic<AnyAction, AnyAction> = action$ =>
    action$.pipe(
        filter(FetchComponentIndexAsync.done.match),
        mergeMap(({ payload: { result: { components } } }) => {
            const themes: ThemeIndex = {};

            const channels: ChannelIndex = {};

            for (const [componentName, { versions, data }] of Object.entries(components)) {
                if (!versions) continue;

                for (const [version, versionInfo] of Object.entries(versions)) {
                    if (!versionInfo?.modules) continue;

                    const themeModules = Object.entries(versionInfo?.modules)
                        .filter(([_, module]) => IsModuleTheme(module));

                    for (const [moduleId, { location, type, options }] of themeModules) {
                        const id = `${componentName}/${version}/${moduleId}`;
                        const displayName = data?.displayName && `${moduleId} ${version}`;

                        themes[id] = { data: { displayName, type: type as "dxp-kit.code.one/style/linked", href: location, class: options?.class } };

                        const channel = GetChannelFromVersion(version);
                        channels[channel] = {
                            ...channels[channel],
                            entries: {
                                ...channels[channel]?.entries,
                                [id]: {},
                            },
                        };
                    }
                }
            }

            return [UpdateThemes({ themes }), UpdateChannels({ channels })];
        })
    );

export default combineEpics(
    componentIndexChannelIntegration,
);
