import { CombineEntityEpics } from "@technologyone/t1-dxp-helper-redux";

import CodeBlockEpic from "../components/asynccodeblock/redux/codeBlockEpic";

import { ComponentEpic } from "./component";
import { ChannelEpic } from "./channels";
import { ThemeEpic } from "./theme";
import CategoryEpic from "./category/categoryEpic";
import ArticleEpic from "./article/articleEpics";

const AppEpic = CombineEntityEpics(
    CodeBlockEpic,
    ComponentEpic,
    CategoryEpic,
    ChannelEpic,
    ThemeEpic,
    ArticleEpic,
);

export default AppEpic;
