import "normalize.css";
import "@technologyone/t1-dxp-config-html";
import "@babel/polyfill";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { HashRouter, Switch, Route } from "react-router-dom";

// redux imports
import Provider from "@technologyone/t1-dxp-component-provider";
import { ConfigureStore } from "@technologyone/t1-dxp-helper-redux";

import AppReducer from "./redux/appReducer";
import AppEpic from "./redux/appEpic";
import AppState from "./redux/appState";

import DesignSystem, { HomePage } from "./designsystem";
import { GoogleAnalyticsProvider } from "./hooks/useGoogleAnalytics";

const Title: React.FunctionComponent<{children: string}> =
({children}) => {
    React.useEffect(() => { window.document.title = children; }, [children]);
    return null;
};

const FavIcon: React.FunctionComponent<{children: string}> =
({children}) => {
    const link = React.useMemo(() => document.querySelectorAll("link[rel='shortcut icon']"), []);
    React.useEffect(() => link.forEach(i => i.setAttribute("href", children)), [children, link]);
    return null;
};

// Application
class Application extends React.Component<any> {

    componentDidMount(): void {
        document.body.classList.add("t1-dxp-style-designsystem", "ds-body");
    }

    store = ConfigureStore<AppState>(AppEpic, AppReducer, { demoMode: true });

    render() {
        return (
            <Provider store={this.store} appStyle={"dxpkit" as "employee"}>
                <Title>DXPkit</Title>
                {/* <FavIcon></FavIcon> */}
                <HashRouter>
                    <GoogleAnalyticsProvider />
                    <Switch>
                        <Route path="/" exact={true} component={HomePage} />
                        <Route>
                            <DesignSystem />
                        </Route>
                    </Switch>
                </HashRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<Application />, document.getElementById("root"));
