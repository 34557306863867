
import { ComponentIndex, ComponentIndex0, IsSchemaVersion } from "@technologyone/t1-dxp-component-designsystem";

import { Component, ComponentVersions } from "../../";
import { Errors } from "../../../../common";
import { ConvertComponentHeader } from "./componentHeader";
import { ConvertComponentVersion } from "./componentVersionHeader";

interface Data {[key: string]: { data: Component, versions: ComponentVersions }; }
type VersionHanlder = (index: ComponentIndex, opts: {componentBaseUrl: string}) => { data: Data } | { errors?: Errors };

export const ConvertComponentIndex: (index: ComponentIndex, opts: {componentBaseUrl: string}) => { errors?: Errors } & { data?: Data } =
(index, {componentBaseUrl}) => {
    if (!index.__metadata?.schema) return { errors: { "DCP-DS-SCP-03": { error: new Error("DCP-DS-SCP-03 Index response is missing version") } } };
    return ComponentIndexVersionHandlers[index.__metadata.schema](index, {componentBaseUrl});
};

const handleV0: VersionHanlder = (index, {componentBaseUrl}) => {
    if (!IsSchemaVersion<ComponentIndex0>(index, "0.1")) return { error: { code: "DCP-DS-SCP-01", error: new Error("DCP-DS-SCP-01 Unknown schema version") } };

    try {
        const response: { errors?: Errors } & { data?: Data } = {};

        Object.entries(index.components || {})
        .forEach(([name, component]) => {
            const convertedComponent = ConvertComponentHeader(component);

            if (convertedComponent.data) response.data = { ...response.data, [name]: { data: convertedComponent.data, versions: {} } };
            if (convertedComponent.errors) response.errors = { ...response.errors, ...convertedComponent.errors };

            Object.entries(component.versions || {})
            .forEach(([version, versionInfo]) => {
                const componentInfo = response.data?.[name];
                if (componentInfo) {
                    componentInfo.versions = {
                        ...componentInfo.versions,
                        [version]: ConvertComponentVersion(versionInfo, {componentBaseUrl: `${componentBaseUrl}/${name}/${version}/`})
                    };
                }
            });
        });

        return response;
    } catch (e) {
        return { errors: { "DCP-DS-SCP-02": { error: new Error("DCP-DS-SCP-02 Unable to parse index response"), innerError: e} } };
    }
};

const ComponentIndexVersionHandlers: { [V in ComponentIndex["__metadata"]["schema"]]: VersionHanlder } = {
    0.1: handleV0,
};
