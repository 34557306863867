import { IsSchemaType } from "@technologyone/t1-dxp-component-designsystem";
import { ConvertCategoryIndex, CategoryIndex, CategoryIndexType } from "./categoryIndex";
import { FetchIndex } from "../..";

export default function(baseUrl: string): FetchIndex {
    const fetchIndex: FetchIndex = async function() {
        const response: Partial<CategoryIndex> = await (await fetch(`${baseUrl}/index.json`, { method: "GET" })).json();

        if (!IsSchemaType<CategoryIndex>(response, CategoryIndexType)) {
            return {
                errors: { "DCP-DS-SCP-00": { error: new Error("DCP-DS-SCP-00 - Invalid index response shape") } },
            };
        }

        return ConvertCategoryIndex(response, { componentBaseUrl: baseUrl });
    };

    return fetchIndex;
}
