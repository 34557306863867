import { Epic, combineEpics } from "redux-observable";
import { AnyAction } from "typescript-fsa";
import { filter, map, mergeMap } from "rxjs/operators";
import { empty } from "rxjs";

import { SetVisibleChannels } from "./channelVisibilityActions";
import { SetVisibleChannels as SetVisibleChannelsService } from "./channelVisibilityService";

const saveVisiblityToLocalStorage: Epic<AnyAction, AnyAction> = action$ =>
action$.pipe(
  filter(SetVisibleChannels.match),
  map(({payload: {visibleChannels}}) => SetVisibleChannelsService(visibleChannels)),
  mergeMap(action => empty()),
);

export default combineEpics(
    saveVisiblityToLocalStorage,
);
