import { CategoryProvider } from "../..";

import fetchIndex from "./fetchIndex";

export const CreateHttpCategoryProvider: (options: { url: string; fileName: string }) => CategoryProvider = ({
    url,
    fileName,
}) => ({
    fetchIndex: fetchIndex(url),
});
