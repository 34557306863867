import { ComponentState, DefaultComponentState, MergeComponentIndexes } from "./componentState";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { FetchComponentAsync, FetchComponentIndexAsync } from "./componentActions";
import { MergeStatus, StandardStatuses } from "../../common";

export const ComponentReducer = reducerWithInitialState<ComponentState>(DefaultComponentState);

ComponentReducer.case(FetchComponentIndexAsync.done, (state, { result, params: { key } }) => {
    const newStatus = { type: FetchComponentIndexAsync.done.type, status: StandardStatuses.Finished };

    const newState: ComponentState = {
        ...state,
        index: MergeComponentIndexes(state.index, result.components) ?? {},
        status: MergeStatus(state.status, { [key]: [newStatus] }),
    };

    return newState;
});

ComponentReducer.case(
    FetchComponentAsync.started,
    (state, { version, name, activeStyle, key }) => {
        const newVersion = {
            status: { [key]: [{ type: FetchComponentAsync.started.type, status: StandardStatuses.Pending }] },
        };

        const newState: ComponentState = {
            ...state,
            index: MergeComponentIndexes(
                state.index,
                { [name]: { versions: { [version]: newVersion } } }
            ) ?? { },
        };
        return newState;
    }
);

ComponentReducer.case(
    FetchComponentAsync.done,
    (state, { params: { version, name, key }, result: { data } }) => {
        const newVersion = {
            status: { [key]: [{ type: FetchComponentAsync.started.type, status: StandardStatuses.Finished }] },
            data,
        };

        const newState: ComponentState = {
            ...state,
            index: MergeComponentIndexes(
                state.index,
                { [name]: { versions: { [version]: newVersion } } }
            ) ?? { },
        };
        return newState;
    }
);
