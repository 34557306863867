import { ObjectMap, ObjectPairByKey } from "@technologyone/t1-dxp-helper-object";

import { Statuses } from "../../common";

export interface CategoryIndex {
    [categoryName: string]: Category | undefined;
}

export interface Category {
    displayName?: string;
    subcategories?: CategoryIndex;
}

export type CategoryState = { index: CategoryIndex } & { status?: Statuses };

export const DefaultCategoryState: CategoryState = {
    index: {},
};

export const MergeComponentState: (first: CategoryState, ...[second, ...remaining]: CategoryState[]) => CategoryState =
    (first, second, ...remaining) => {
        if (!second) return first;

        const result = [first, second, ...remaining].reduce((prev, next) => ({
            ...prev,
            ...next,
            index: MergeComponentIndexes(first.index, second.index),
        }));
        return result;
    };

export const MergeComponentIndexes: (first?: CategoryIndex, second?: CategoryIndex) => CategoryIndex = (
    first,
    second
) => {
    if (!first) return second ?? {};
    if (!second) return first ?? {};

    return {
        ...first,
        ...second,
        ...ObjectMap(first, (id, keyword) => MergeCategories(keyword, second[id]) ?? {}),
    };
};

export const MergeCategories: (first?: Category, second?: Category) => Category = (first, second) => {
    if (!first) return second ?? {};
    if (!second) return first ?? {};

    return {
        ...first,
        ...second,
        subcategories: ObjectPairByKey(first?.subcategories ?? {}, second.subcategories ?? {}, MergeCategories),
    };
};
