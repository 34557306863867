import { Errors, Immutable, Statuses } from "../../common";

export type Component =
    {data?: { displayName: string; category: string; } }
    & { versions?: { [key: string]: ComponentVersion | undefined } }
    & { error?: Errors }
    & { status?: Statuses };

export type ComponentVersion =
    { errors?: Errors }
    & { status?: Statuses }
    & {
        data?: {
            name: string;
            description: string;
            packageJson: object;
            pages: ComponentPageSet;
            category: string;
        }
    };

export interface ComponentPageSet {
    [tabId: string]: {
        labelText?: string,
        component: React.ReactNode
    };
}

export interface ComponentIndex { [componentName: string]: Component | undefined; }

export type ComponentState = Immutable<
    { index: ComponentIndex; }
    & { status?: Statuses }
>;

export const DefaultComponentState: ComponentState = {
    index: {},
};

export const MergeComponentIndexes: (...componentsComponents: Array<Immutable<ComponentIndex> | undefined>) => Immutable<ComponentIndex> | undefined =
(first, second, ...remaining) => {
    if (!second || !first) return second || first;
    return MergeComponentIndexes({
        ...first,
        ...Object.entries(second)
            .map(([name, component]) => ({ [name]: MergeComponent(first?.[name], component) }))
            .reduce((curr, next) => ({ ...curr, ...next }), {})
    }, ...remaining);
};

export const MergeComponent: (...components: Array<Immutable<Component> | undefined>) => Immutable<Component> | undefined =
(first, second, ...remaining) => {
    if (!second || !first) return second || first;
    return MergeComponent({
        ...first,
        ...second,
        data: second?.data || first.data,
        versions: MergeVersions(first?.versions, second?.versions),
    }, ...remaining);
};

export const MergeVersions: (...versions: Array<Immutable<Component["versions"]>>) => Immutable<Component["versions"]> | undefined =
(first, second, ...remaining) => {
    if (!second || !first) {
        return second || first;
    }

    return MergeVersions({
        ...first,
        ...Object.entries(second ?? { abc: "DEF" })
            .map(([v, version]) => ({ [v]: { ...first?.[v], ...version } }))
            .reduce((curr, next) => ({ ...curr, ...next }), {})
    }, ...remaining);
};
