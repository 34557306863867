import { Immutable } from "./immutable";

interface StatusEntry {
    type: string;
    status: StandardStatuses;
}

export interface Statuses { [key: string]: StatusEntry[]; }

export function MergeDictionary<T extends Immutable<Record<string, V>>, V>(
    merge: (old: Immutable<V>, incomming: Immutable<V>) => V,
    old: T,
    ...incomming: T[]): T {
    if (!incomming.length) return old;

    return MergeDictionary(
        merge,
        {
            ...old,
            ...Object.entries(incomming[0])
                .map(([prop, value]) => ({[prop]: merge(old[prop], value)}))
                .reduce((curr, next) => ({...curr, ...next}), {})
        },
        ...incomming.slice(1)
    );
}

export function MergeStatus<S extends Immutable<Statuses>>(old?: S, ...incomming: S[]): S {
    if (!incomming?.length || !incomming) return old;
    if (!old) return incomming[0];

    const merged = MergeDictionary<S, Array<{ type: string; status: StandardStatuses; }>>((o, n) => [...o, ...n], old, incomming[0]);

    return MergeStatus(
        merged,
        ...incomming.splice(1)
    );
}

export enum StandardStatuses {
    Finished = "finished",
    Pending = "pending",
    Error = "error",
}
