/* eslint-disable no-underscore-dangle */
import { IsSchemaVersion, Schema } from "@technologyone/t1-dxp-component-designsystem";

import { Errors } from "../../../../common";
import { CategoryIndex as CategoryIndexState } from "../../../../redux/category";
import { CategoryHeader, ConvertCategoryHeader } from "./categoryHeader";

export const CategoryIndexType = "dxp-kit.code.one/dxp-category/index";
export interface CategoryIndex0 extends Schema<typeof CategoryIndexType, "0.1"> {
    categories: {
        [categoryName: string]: CategoryHeader;
    };
}
export declare type CategoryIndex = CategoryIndex0;

type VersionHandler = (
    index: CategoryIndex,
    opts: { componentBaseUrl: string }
) => { data: CategoryIndexState } | { errors?: Errors };

export const ConvertCategoryIndex: (
    index: CategoryIndex,
    opts: { componentBaseUrl: string }
) => { errors?: Errors } & { data?: CategoryIndexState } = (index, { componentBaseUrl }) => {
    if (!index.__metadata?.schema) {
        return { errors: { "DCP-DS-SCP-03": { error: new Error("DCP-DS-SCP-03 Index response is missing version") } } };
    }
    return CategoryIndexVersionHandlers[index.__metadata.schema](index, { componentBaseUrl });
};

const handleV0: VersionHandler = (index, { componentBaseUrl }) => {
    if (!IsSchemaVersion<CategoryIndex0>(index, "0.1")) {
        return { error: { code: "DCP-DS-SCP-01", error: new Error("DCP-DS-SCP-01 Unknown schema version") } };
    }

    try {
        const response: { errors?: Errors } & { data?: CategoryIndexState } = {};

        Object.entries(index.categories || {}).forEach(([name, component]) => {
            const convertedCategory = ConvertCategoryHeader(component);

            if (convertedCategory.data) {
                response.data = { ...response.data, [name]: convertedCategory.data };
            }
            if (convertedCategory.errors) {
                response.errors = { ...response.errors, ...convertedCategory.errors };
            }
        });

        return response;
    } catch (e) {
        return {
            errors: {
                "DCP-DS-SCP-02": { error: new Error("DCP-DS-SCP-02 Unable to parse index response"), innerError: e },
            },
        };
    }
};

const CategoryIndexVersionHandlers: { [V in CategoryIndex["__metadata"]["schema"]]: VersionHandler } = {
    0.1: handleV0,
};
