import * as Reducers from "typescript-fsa-reducers";

import ArticleState, { InitialArticleState } from "./articleState";
import * as ArticleActions from "./articleActions";
import { ServerStatus } from "../common";

const ArticleReducer = Reducers.reducerWithInitialState<ArticleState>(InitialArticleState)
    .case(ArticleActions.GetArticleAsync.started, (state, { knowledgeBaseId, location: articleLocation }) => ({
        ...state,
        [knowledgeBaseId]: {
            ...state[knowledgeBaseId],
            data: {
                ...state[knowledgeBaseId]?.data,
                [articleLocation]: {
                    ...state[knowledgeBaseId]?.data?.[articleLocation],
                    status: ServerStatus.Pending,
                },
            },
        },
    }))
    .case(ArticleActions.GetArticleAsync.done, (state, { params: { knowledgeBaseId, location: articleLocation }, result: { article } }) => ({
        ...state,
        [knowledgeBaseId]: {
            ...state[knowledgeBaseId],
            data: {
                ...state[knowledgeBaseId]?.data,
                [articleLocation]: {
                    data: article,
                    status: ServerStatus.Available,
                },
            },
        },
    }))
    .case(ArticleActions.GetArticleAsync.failed, (state, { params: { knowledgeBaseId, location: articleLocation }, error }) => ({
        ...state,
        [knowledgeBaseId]: {
            ...state[knowledgeBaseId],
            data: {
                ...state[knowledgeBaseId]?.data,
                [articleLocation]: {
                    status: ServerStatus.Errored,
                    error,
                },
            },
        },
    }));

export default ArticleReducer;
