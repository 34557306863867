import { combineEpics, Epic } from "redux-observable";
import { filter, groupBy, map, mergeMap, tap, throttleTime } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { v4 as uuidV4 } from "uuid";

import { ComponentProvider } from "../../services/components";

import { FetchComponent, FetchComponentAsync, FetchComponentIndex, FetchComponentIndexAsync } from "./componentActions";
import { concat, from, of } from "rxjs";

const fetchComponentIndexEpic: Epic<AnyAction, AnyAction> = action$ =>
    action$.pipe(
        filter(FetchComponentIndex.match),
        throttleTime(5_000),
        map(action => ({ ...action, payload: { ...action.payload, key: action.payload.key || uuidV4() } })),
        mergeMap(action => concat(
            of(FetchComponentIndexAsync.started(action.payload)),
            from(ComponentProvider.fetchIndex())
                .pipe(
                    tap(({ errors }) => console.assert(!errors, "DCP-DS-CIE-00 Error fetching component index", errors)),
                    filter(result => !!result.data),
                    map(({ data }) => FetchComponentIndexAsync.done({ params: action.payload, result: { components: data ?? {} } }))
                )
        )),
    );

const fetchComponentEpic: Epic<AnyAction, AnyAction> = action$ =>
    action$.pipe(
        filter(FetchComponent.match),
        groupBy(({ payload: { name, version, activeStyle } }) => JSON.stringify({ name, version, activeStyle })),
        mergeMap(component$ => component$.pipe(throttleTime(5_000))),
        map(action => ({ ...action, payload: { ...action.payload, key: action.payload.key || uuidV4() } })),
        mergeMap(action => concat(
            of(FetchComponentAsync.started(action.payload)),
            from(ComponentProvider.fetchComponent(action.payload.name, action.payload.version, action.payload.activeStyle))
                .pipe(
                    tap(({ errors }) => console.assert(!errors, "DCP-DS-CIE-00 Error fetching component index", errors)),
                    filter(result => !!result),
                    map(({ data, errors }) => FetchComponentAsync.done({ params: action.payload, result: { data, errors } }))
                )
        )),
    );

export default combineEpics(
    fetchComponentIndexEpic,
    fetchComponentEpic,
);
