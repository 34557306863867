import * as Reducers from "typescript-fsa-reducers";

import ObjectHelper from "@technologyone/t1-dxp-helper-object";

import { FetchCodeBlockAsync } from "./codeBlockActions";
import CodeBlockState, { InitialCodeBlockState } from "./codeBlockState";

const CodeBlockReducer = Reducers.reducerWithInitialState<CodeBlockState>(InitialCodeBlockState)
    .case(FetchCodeBlockAsync.done, (state, action) => {
        let newState = ObjectHelper.Clone(state);

        newState[action.result.codeBlockName] = action.result.code;

        return newState;
    });

export default CodeBlockReducer;
