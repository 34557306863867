// Third Party Imports
import { Action, Location } from "history";
import * as React from "react";
import { useHistory } from "react-router";

// TechnologyOne Package Imports
import InputBase from "@technologyone/t1-dxp-component-input-base";
import { IsValidElement } from "@technologyone/t1-dxp-helper-react";
import { IsEmpty } from "@technologyone/t1-dxp-helper-array";

// Internal Imports
import NavGroupItem, { NavGroupItemProps } from "./navGroupItem";
import NavItem, { NavItemProps } from "./navItem";
import NavBarContext from "./navBarContext";

// Style Imports
import Styles from "./navBar.less";

// Global Functions
const Search = (children: React.ReactNode, searchTerm: string): React.ReactElement<NavItemProps>[] | undefined => {
    let foundItems: React.ReactElement<NavItemProps>[] = [];

    const searchTerms = searchTerm.toLowerCase().split(" ");

    React.Children.forEach(children, child => {
        if (IsValidElement<NavItemProps>(child, NavItem)) {
            if (searchTerms.every(sub => (
                IsEmpty(child.props.searchKeywords)
                    ? child.props.text.toLowerCase().includes(sub)
                    : child.props.searchKeywords.some(searchKeyword => searchKeyword.includes(sub))
            ))) {
                foundItems.push(child);
            }
        } else if (IsValidElement<NavGroupItemProps>(child, [NavGroupItem, React.Fragment])) {
            const childItems = Search(child.props.children, searchTerm);
            if (childItems) {
                foundItems = [
                    ...foundItems,
                    ...childItems,
                ];
            }
        }
    });

    return foundItems;
};


// Component Definition
interface NavBarProps {
    currentRoute?: string;
    children: React.ReactNode;
}

const NavBar: React.FunctionComponent<NavBarProps> = ({
    currentRoute,
    children,
}) => {
    const history = useHistory();

    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchResults, setSearchResults] = React.useState<React.ReactElement<NavItemProps>[] | undefined>(undefined);

    const handleSearchChange = React.useCallback((e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchTerm(e.currentTarget.value);
    }, []);

    const handleClear = React.useCallback(() => {
        setSearchTerm("");
    }, []);

    React.useEffect(() => {
        if (searchTerm) {
            const searchItems = Search(children, searchTerm);
            setSearchResults(searchItems);
        } else {
            setSearchResults(undefined);
        }
    }, [searchTerm, children]);

    const handleRouteChange = React.useCallback((location: Location, action: Action) => {
        handleClear();
    }, [handleClear]);

    // Effects
    React.useEffect(() => {
        const unregisterListen = history.listen(handleRouteChange);

        return () => {
            unregisterListen();
        };
    }, [history, handleRouteChange]);

    return (
        <div className={`dxpNavBar ${searchResults ? Styles.searching : ""}`}>
            <InputBase className="dxpNavBarSearch"
                labelText=""
                width="full"
                clearable={true}
                placeholder="Search"
                invertColors={true}
                value={searchTerm}
                onChange={handleSearchChange}
                onClear={handleClear}
            />
            <NavBarContext.Provider value={{ currentRoute }}>
                {searchResults ?? children}
            </NavBarContext.Provider>
        </div>
    );
};

export default NavBar;
export { NavBarProps };
