import "normalize.css";
import "@technologyone/t1-dxp-config-html";
import "@technologyone/t1-dxp-style-designsystem";

// React Components Imports
import * as React from "react";
import Loadable from "react-loadable";
import { Link, Redirect, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import Button, { CoreSvgs } from "@technologyone/t1-dxp-component-button";
import { MainLayoutSkeleton } from "@technologyone/t1-dxp-component-designsystem";
import ArrayHelper from "@technologyone/t1-dxp-helper-array";

// Sub-Component Imports
import NavBar, { Separator as NavBarSeparator, NavGroupItem, NavItem } from "./components/navbar";
import { GetChannelComponents, MergeChannels } from "./redux/channels";
import { ComponentState, FetchComponentIndex, GetDefaultComponentVersion } from "./redux/component";
import AppState from "./redux/appState";
import { CategoryState, FetchCategoryIndex } from "./redux/category";
import TokensPage from "./pages/tokens";

import { ComponentRoute } from "./routing";

// Component Style Imports
import Styles from "./index.less";

// Views
const HomePage = Loadable({
    loader: () => import("./pages/home"),
    loading: () => <div>
        <div style={{ backgroundColor: "#24323a", height: "702px", padding: "20px 20px 80px 20px", boxSizing: "border-box" }}>
            <div style={{ backgroundColor: "rgba(255,255,255,0.1)", width: "186px", height: "16px", borderRadius: "16px" }}></div>
            <div style={{ maxWidth: "1000px", margin: "0 auto", padding: "0 16px 16px 16px" }}>
                <div style={{ padding: "calc(10vh + 20px) 0 12vh 0" }}>
                    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", width: "105px", height: "16px", borderRadius: "16px" }}></div>
                    <div style={{ padding: "12px 0 24px 0" }}>
                        <div style={{ backgroundColor: "rgba(255,255,255,0.1)", width: "340px", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
                        <div style={{ backgroundColor: "rgba(255,255,255,0.1)", width: "340px", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
                        <div style={{ backgroundColor: "rgba(255,255,255,0.1)", width: "340px", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
                    </div>
                </div>
                <div  style={{ display: "flex", justifyContent: "spaceBetween", gap: "8px", marginTop: "12px" }}>
                    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", flex: "1", height: "208px", borderRadius: "2px" }}></div>
                    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", flex: "1", height: "208px", borderRadius: "2px" }}></div>
                    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", flex: "1", height: "208px", borderRadius: "2px" }}></div>
                    <div style={{ backgroundColor: "rgba(255,255,255,0.1)", flex: "1", height: "208px", borderRadius: "2px" }}></div>
                </div>
            </div>
        </div>
        <div style={{ maxWidth: "1000px", margin: "0 auto", backgroundColor: "#fff", padding: "80px", boxSizing: "border-box" }}>
            <div style={{ backgroundColor: "#eef5f7", width: "143px", height: "16px", borderRadius: "16px", marginTop: "12px" }}></div>
            <div style={{ backgroundColor: "#eef5f7", width: "100%", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
            <div style={{ backgroundColor: "#eef5f7", width: "100%", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
            <div style={{ backgroundColor: "#eef5f7", width: "60%", height: "12px", borderRadius: "16px", marginTop: "12px" }}></div>
        </div>
    </div>,
});

const ComponentStatusPage = Loadable({
    loader: () => import("./pages/componentStatus"),
    loading: () => <MainLayoutSkeleton />,
});

const ComponentFromProviderPage = Loadable({
    loader: () => import("./pages/components/componentFromProvider"),
    loading: () => <MainLayoutSkeleton />,
});

const ComponentFromProvider: React.FunctionComponent<{match: any, history: any}> =
({ match: { params: { scope, name, version, tab } }, history }) => {
    const onComponentChange = React.useCallback(component => ComponentRoute(component).go(history), [history]);
    return <ComponentFromProviderPage
        onComponentChange={onComponentChange}
        name={`${scope}/${name}`}
        version={version}
        tab={tab}
    />;
};

const DeveloperPage = Loadable({
    loader: () => import("./pages/gettingStarted/developers"),
    loading: () => <div>
        <div style={{ boxSizing: "border-box", width: "100%", minHeight: "172px", display: "flex", flexDirection: "column", backgroundColor: "#eef5f7" }}>
            <div style={{ paddingBottom: "var(--layoutSectionPaddingB)", paddingLeft: "var(--layoutSectionPaddingLR)", paddingRight: "calc(var(--dxpKitMainLayoutElementExtraSpacingRight) + var(--layoutSectionPaddingLR))", paddingTop: "var(--layoutSectionPaddingT)", minHeight: "125px" }}>
                <div style={{ backgroundColor: "#d0dfe7", width: "200px", height: "32px", borderRadius: "16px", marginTop: "22px" }}></div>
                <div style={{ backgroundColor: "#d0dfe7", width: "340px", height: "20px", borderRadius: "16px", marginTop: "32px" }}></div>
            </div>
            <div className="inner-tabs">
                <div className="dxpSkeletonElement tab"></div>
                <div className="dxpSkeletonElement tab"></div>
                <div className="dxpSkeletonElement tab"></div>
            </div>
        </div>
    </div>,
});

const BusinessAnalystPage = Loadable({
    loader: () => import("./pages/gettingStarted/businessAnalysts"),
    loading: () => <div>
        <div style={{ boxSizing: "border-box", width: "100%", minHeight: "172px", display: "flex", flexDirection: "column", backgroundColor: "#eef5f7" }}>
            <div style={{ paddingBottom: "var(--layoutSectionPaddingB)", paddingLeft: "var(--layoutSectionPaddingLR)", paddingRight: "calc(var(--dxpKitMainLayoutElementExtraSpacingRight) + var(--layoutSectionPaddingLR))", paddingTop: "var(--layoutSectionPaddingT)", minHeight: "125px" }}>
                <div style={{ backgroundColor: "#d0dfe7", width: "200px", height: "32px", borderRadius: "16px", marginTop: "22px" }}></div>
                <div style={{ backgroundColor: "#d0dfe7", width: "340px", height: "20px", borderRadius: "16px", marginTop: "32px" }}></div>
            </div>
            <div className="inner-tabs">
                <div className="dxpSkeletonElement tab"></div>
                <div className="dxpSkeletonElement tab"></div>
                <div className="dxpSkeletonElement tab"></div>
            </div>
        </div>
    </div>,
});

const AccessibilityPage = Loadable({
    loader: () => import("./pages/accessibility"),
    loading: () => <div>Loading</div>,
});

const ColourPage = Loadable({
    loader: () => import("./pages/colour"),
    loading: () => <div>Loading</div>,
});

const IconPage = Loadable({
    loader: () => import("./pages/icon"),
    loading: () => <div>Loading</div>,
});

/* const CSSGridPage = Loadable({
    loader: () => import("./pages/cssgrid"),
    loading: () => <div>Loading</div>,
}); */

/* const LayoutPageHome = Loadable({
    loader: () => import("./pages/layoutHome"),
    loading: () => <div>Loading</div>,
});

const LayoutPageDocument = Loadable({
    loader: () => import("./pages/layoutDocument"),
    loading: () => <div>Loading</div>,
});

const LayoutPageSearch = Loadable({
    loader: () => import("./pages/layoutSearch"),
    loading: () => <div>Loading</div>,
}); */

const SettingsPage = Loadable({
    loader: () => import("./pages/settings"),
    loading: () => <div>Loading</div>,
});



/* const TypographyPage = Loadable({
    loader: () => import("./pages/typography"),
    loading: () => <div>Loading</div>
}) */


interface NavigationItem {
    text: string;
    url: string;
    searchKeywords: Array<string>;
}

interface CategoryItem {
    id: string;
    text: string;
    items?: NavigationItem[];
    subCategories?: CategoryItem[];
}

interface StateProps {
    components: ComponentState["index"];
    categories: CategoryState["index"];
}
interface DispatchProps {
    fetchComponents: () => void;
    fetchCategories: () => void;
}

interface OwnProps { }

interface DesignSystemState {
    isMenuOpen: boolean;
}

type DesignSystemProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const SortNavigationItems = (a: { text:string }, b: { text: string }) => {
    const titleA = a.text.toUpperCase();
    const titleB = b.text.toUpperCase();

    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
};

class DesignSystem extends React.Component<DesignSystemProps, DesignSystemState> {

    constructor(props: DesignSystemProps) {
        super(props);
        this.state = { isMenuOpen: false };
    }

    GetNavigationItems() {

        let categories: CategoryItem[];
        let products: CategoryItem;

        if (this.props.categories && this.props.components) {
            const componentsCategoriesMap =  Object.entries(this.props.components)
                .reduce((total: { [key: string]: NavigationItem[]}, [name, { data, versions }]) => {
                    if (!data || !data.category || data.category === "Unknown") return total;

                    const versionsList = versions && Object.keys(versions);
                    if (!versionsList || versionsList.length === 0) return total;

                    return {
                        ...total,
                        [data.category.toLowerCase()]: [
                            ...total[data.category.toLowerCase()] ?? [],
                            {
                                text: data.displayName,
                                url: ComponentRoute({ name }).href,
                                searchKeywords: [
                                    data.displayName,
                                    name,
                                    `@technologyone/${name}`,
                                ],
                            },
                        ],
                    };
                }, {});

            ({ categories, products } = Object.entries(this.props.categories)
                .map(([categoryId, category]) => ({
                    id: categoryId,
                    text: category.displayName ?? "",
                    items: componentsCategoriesMap[categoryId?.toLowerCase()]?.sort(SortNavigationItems),
                    subCategories: Object.entries(category.subcategories ?? {})
                        .map(([subcategoryId, subcategory]) => ({
                            id: subcategoryId,
                            text: subcategory.displayName ?? "",
                            items: componentsCategoriesMap[subcategoryId?.toLowerCase()]?.sort(SortNavigationItems),
                        }))
                        .filter(subCategory => subCategory.id && subCategory.text && !ArrayHelper.IsEmpty(subCategory.items))
                        .sort(SortNavigationItems),
                }))
                .filter(category => category.id && category.text && (!ArrayHelper.IsEmpty(category.subCategories) || !ArrayHelper.IsEmpty(category.items)))
                .reduce((total: { categories?: CategoryItem[], products?: CategoryItem }, current) => (
                    current.id === "Products"
                        ? {
                            ...total,
                            products: current,
                        }
                        : {
                            ...total,
                            categories: [
                                ...total.categories ?? [],
                                current,
                            ],
                        }
                ), {}));
        }

        return (
            <>
                <NavGroupItem text="Getting Started">
                    <NavItem text="Business Analysts" url="/gettingStarted/businessAnalysts" />
                    <NavItem text="Developers" url="/gettingStarted/developers" />
                </NavGroupItem>
                {categories?.map(({ id, text, items, subCategories }) => (
                    <NavGroupItem text={text} key={text}>
                        {items?.map(item => (
                            <NavItem text={item.text} url={item.url} searchKeywords={item.searchKeywords} key={item.url} />
                        ))}
                        {subCategories?.map(subCategory => (
                            <NavGroupItem text={subCategory.text} key={subCategory.id}>
                                {subCategory.items?.map(item => (
                                    <NavItem text={item.text} url={item.url} searchKeywords={item.searchKeywords} key={item.url} />
                                ))}
                            </NavGroupItem>
                        ))}
                    </NavGroupItem>
                ))}
                <NavGroupItem text="Styling">
                    <NavItem text="Tokens" url="/tokens" />
                    <NavItem text="Icons" url="/icon" />
                </NavGroupItem>
                <NavItem text="Accessibility" url="/accessibility" />
                {products && (
                    <>
                        <NavBarSeparator />
                        <NavGroupItem text={products.text} key={products.text}>
                            {products.items?.map(item => (
                                <NavItem text={item.text} url={item.url} key={item.url} />
                            ))}
                            {products.subCategories?.map(subCategory => (
                                <NavGroupItem text={subCategory.text} key={subCategory.id}>
                                    {subCategory.items?.map(item => (
                                        <NavItem text={item.text} url={item.url} key={item.url} />
                                    ))}
                                </NavGroupItem>
                            ))}
                        </NavGroupItem>
                    </>
                )}
            </>
        );
    }

    componentDidMount() {
        this.props.fetchComponents();
        this.props.fetchCategories();
    }

    getDefaultComponentVersion(scope: string, name: string): React.ReactNode {
        const componentVersions = Object.keys(this.props.components[`${scope}/${name}`]?.versions ?? {});
        const version = GetDefaultComponentVersion(componentVersions);

        return version
            ? <Redirect to={ComponentRoute({ name: `${scope}/${name}`, version }).location} />
            : "No Version";
    }

    // Application
    render() {
        return (
            <div className="ds-layout">
                <div className="ds-layout-InnerCntr">
                    <div className="ds-layout-GridCntr">
                        <div className="ds-layout-NavigationCntr">
                            <div className={Styles.logoCntr}>
                                <div id="DSLayoutShowMenuBtnCntr">
                                    <Button
                                        tabIndex={0}
                                        aria-label={`${this.state.isMenuOpen ? "Hide Menu" : "Show Menu"}`}
                                        onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}
                                    >
                                        <div id="DSLayoutShowMenuIconTop" />
                                        <div id="DSLayoutShowMenuIconMiddle"/>
                                        <div id="DSLayoutShowMenuIconBottom"/>
                                    </Button>
                                </div>
                                <div className={Styles.homeLinkCntr}>
                                    <Link to="/" className={Styles.homeLink}>DXPkit</Link>
                                    <Button icon={CoreSvgs.Settings} onClick="/settings" title="Settings" invertColours={true} />
                                </div>
                            </div>
                            <div className={`ds-layout-ScrollableNavigationCntr${this.state.isMenuOpen ? " showNavMenu" : ""}`}>
                                <Link id="DSLayoutSideNavHomeLink" to="/">DXPkit</Link>
                                <NavBar currentRoute={this.props.location.pathname}>
                                    {this.GetNavigationItems()}
                                </NavBar>
                            </div>
                        </div>
                        <div className="ds-layout-Content">
                            <Route path="/components" exact={true} component={ComponentStatusPage} />
                            <Route path="/gettingStarted/businessAnalysts" exact={true} component={BusinessAnalystPage} />
                            <Route path="/gettingStarted/developers" exact={true} component={DeveloperPage} />
                            <Route path="/accessibility" exact={true} component={AccessibilityPage} />
                            <Route path="/colour" exact={true} component={ColourPage} />
                            <Route path="/componentStatus" exact={true} component={ComponentStatusPage} />
                            <Route path="/tokens" exact={true} component={TokensPage} />
                            <Route path="/icon" exact={true} component={IconPage} />
                            {/* <Route path="/cssgrid" exact={true} component={CSSGridPage} /> */}
                            {/* <Route path="/layoutHome" exact={true} component={LayoutPageHome} />
                            <Route path="/layoutDocument" exact={true} component={LayoutPageDocument} />
                            <Route path="/layoutSearch" exact={true} component={LayoutPageSearch} /> */}
                            <Route path="/settings" exact={true} component={SettingsPage} />
                            {/* <Route path="/typography" exact={true} component={TypographyPage} /> */}
                            <Route
                                exact={true}
                                path="/component/:scope/:name"
                                render={({ match: { params: { scope, name } } }) => this.getDefaultComponentVersion(scope, name)}
                            />
                            <Route
                                path="/component/:scope/:name/:version/:tab?"
                                exact={true}
                                component={ComponentFromProvider}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
    ({ component: { index: components }, channel: { index: channels, visibleChannels: visibleChannelNames }, category: { index: categories } }) => {

        const visibleChannels = MergeChannels(...visibleChannelNames.map(channelName => channels[channelName]));

        return {
            components: GetChannelComponents(visibleChannels, components),
            categories,
        };
    };

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> =
    dispatch => ({ fetchComponents: () => dispatch(FetchComponentIndex({})), fetchCategories: () => dispatch(FetchCategoryIndex({})) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignSystem));
export { HomePage };
