
import { ChannelIndexReducer, ChannelIndex } from "./channelIndex";
import { ChannelVisibility, ChannelVisibilityReducer, ChannelVisibilityEpic } from "./visibility";

import { ChannelIntegrationEpic } from "./componentIntegration";

import { Immutable } from "../../common";
import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";

export type ChannelState = Immutable<
    { index: ChannelIndex; }
    & { visibleChannels: ChannelVisibility; }
>;

export { UpdateChannels, MergeChannels, ChannelIndex } from "./channelIndex";
export { GetVisibleChannelEntries } from "./channelIndexVisibilityIntegration";
export { GetChannelComponents, GetComponentChannelKey, GetChannelFromVersion } from "./componentIntegration";
export { SetVisibleChannels } from "./visibility";

export const ChannelEpic = combineEpics(
    ChannelIntegrationEpic,
    ChannelVisibilityEpic,
);

export const ChannelReducer = combineReducers<ChannelState>({
    index: ChannelIndexReducer,
    visibleChannels: ChannelVisibilityReducer}
);
