import { ComponentDefinition0, ComponentLayoutPageSet } from "@technologyone/t1-dxp-component-designsystem";

import { Errors } from "../../common";

import { CreateLocalComponentProvider, CreateHttpComponentProvider } from "./componentProviders";

const DesignSystemBaseUrl = "/dxp-components";
const DesignSystemFileName = "designsystem.js";

export type ComponentVersion =
    { errors?: Errors }
    & {
        data?: {
            name: string;
            description: string;
            packageJson: object;
            pages: ComponentLayoutPageSet;
            category: string;
        }
    }
    & {
        modules?: { [key: string]: {type: string, location: string, options?: any} }
    };

export interface ComponentVersions { [key: string]: ComponentVersion; }

export interface Component {
    displayName: string;
    category: string;
}

export type FetchIndex = () => Promise<{ data?: {[key: string]: { data?: Component, versions?: ComponentVersions }} } & { errors?: Errors}>;
export type FetchComponent = (name: string, version: string, activeStyle?: { href: string; class?: string; }) => Promise<ComponentVersion>;

export interface ComponentProvider {
    fetchIndex: FetchIndex;
    fetchComponent: FetchComponent;
}

const localComponentProvder = CreateLocalComponentProvider();
const s3ComponentProvider = CreateHttpComponentProvider(
    {url: DesignSystemBaseUrl, fileName: DesignSystemFileName});

export const ComponentProvider: ComponentProvider = {
    fetchIndex: async () => {
        const [localIndex, s3Index] = await Promise.all([
            localComponentProvder.fetchIndex(),
            s3ComponentProvider.fetchIndex(),
        ]);

        return {
            data: { ...localIndex.data, ...s3Index.data },
            errors: { ...localIndex.errors, ...s3Index.errors },
        };
    },

    fetchComponent: async (name, version, activeStyle) => {
        const [localComponent, s3Component] = await Promise.all([
            localComponentProvder.fetchComponent(name, version, activeStyle),
            s3ComponentProvider.fetchComponent(name, version, activeStyle),
        ]);

        return (localComponent as { data: ComponentDefinition0 }).data !== undefined
            ? localComponent
            : s3Component;
    }
};
