
import { Epic, combineEpics } from "redux-observable";
import { AnyAction } from "typescript-fsa";
import { filter, map } from "rxjs/operators";
import Semver from "semver";

import { FetchComponentIndexAsync, ComponentIndex, MergeComponentIndexes } from "../component";

import { UpdateChannels, Channel } from "./channelIndex";
import { Immutable } from "../../common";

export const GetComponentChannelKey = (component: string, version: string) => `${component}@${version}`;

export const GetChannelFromVersion: (version: Semver.SemVer | null | string) => string =
version => {
  const semver = Semver.parse(version);
  const prerelease = semver
    ? semver.prerelease[0]?.toString() ?? "latest"
    : "none";
  return prerelease;
};

export const GetChannelComponents =
  (channel: Immutable<Channel> | undefined, components: Immutable<ComponentIndex>) => Object.values(channel?.entries ?? {})
  .map(({component}) => component)
  .filter((x): x is Immutable<{name: string, version: string}> => !!x)
  .map(componentKeys => ({ ...componentKeys, component: components[componentKeys.name]}))
  .map(({name, version, component}) => ({ name, version, component, versionInfo: component?.versions?.[version] }))
  .map(({name, component, version, versionInfo}): Immutable<ComponentIndex> => ({[name]: { ...component, versions: { [version]: versionInfo } }}))
  .reduce((c, n) => MergeComponentIndexes(c, n), {});

const connectComponentIndex: Epic<AnyAction, AnyAction> = action$ =>
action$.pipe(
  filter(FetchComponentIndexAsync.done.match),
  map(({payload: {params: {key}, result: {components}}}) => {

    const channels: {[channel: string]: Channel} = { };

    for (const [componentName, component] of Object.entries(components)) {
      if (!component.versions) continue;
      for (const [version, _] of Object.entries(component.versions)) {

        const prerelease = GetChannelFromVersion(version);
        channels[prerelease] = channels[prerelease] || { entries: { } };

        channels[prerelease].entries[GetComponentChannelKey(componentName, version)] = { component: { name: componentName, version } };
      }
    }

    return UpdateChannels({key, channels});
  }),
);

export const ChannelIntegrationEpic = combineEpics(connectComponentIndex);
