
const storageKey = "t1-dxp-ds-channels-visibility";

const visibleChannelsType = "dxp-kit.code.one/channels/visibility" as const;

interface VisibleChannels {
    __metadata: { type: typeof visibleChannelsType, schema: "0.1" }
    channels: string[];
}

const isVisibleChannels: (test: any) => test is VisibleChannels =
 (test): test is VisibleChannels =>
    !!test
    && typeof test === "object"
    && !!test.__metadata
    && test.__metadata.type === visibleChannelsType
    && test.__metadata.schema === "0.1"
    && !!test.channels
    && Array.isArray(test.channels)
    && !(test.channels as any[]).some(x => typeof x !== "string");

export const GetVisibleChannels = (): string[] | undefined => {
    try {
        const storage = localStorage.getItem(storageKey);
        if (!storage) return undefined;

        const response = JSON.parse(storage);

        if (!isVisibleChannels(response)) throw new Error("Invalid value");

        return response.channels;
    } catch (e) {
        console.error("DCP-DS-CS-01 - Error reading channel visibility from storage", e);
        return undefined;
    }
};

export const SetVisibleChannels = (visibleChannels: string[]): void => {
    if (!visibleChannels) return;

    const store: VisibleChannels = {
        __metadata: { type: visibleChannelsType, schema: "0.1" },
        channels: ArrayUnique(visibleChannels),
    };

    try {
        localStorage.setItem(storageKey, JSON.stringify(store));
    } catch (e) {
        console.error("DCP-DS-CS-01 - Error saving channel visibility to storage", e);
        return undefined;
    }
};

function ArrayUnique<T>(arr: T[], equality: (a: T, b: T) => boolean = (a, b) => a === b) {
    return arr.filter((a, i, self) => self.findIndex(b => equality(a, b)) === i);
}
