import { ArticleId } from "@technologyone/t1-lg-component-article";

export enum HTTPMethod {
    GET = "GET",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}

export const GenerateArticleEndpoint = (knowledgeBaseId: string, articleLocation: ArticleId): string => `/Services/ENV/LGCRM/KnowledgeBase/${knowledgeBaseId}/Article/${articleLocation}`;

export enum KnowledgeBaseErrors {
    NotFound = "NotFound",
    CreateFailed = "CreateFailed",
    UpdateFailed = "UpdateFailed",
    DeleteFailed = "DeleteFailed",
    NetworkError = "NetworkError"
}
