// Third Party Imports
import * as React from "react";

// TechnologyOne Package Imports
import Icon, { CoreSvgs } from "@technologyone/t1-dxp-component-icon";
import { IsValidElement } from "@technologyone/t1-dxp-helper-react";

// Internal Imports
import NavBarContext from "./navBarContext";
import NavItem, { NavItemProps } from "./navItem";

// GLobal Functions
const IsChildSelected = (children: React.ReactNode | undefined, currentRoute: string | undefined) => {
    if (!currentRoute) return false;

    let isChildSelected = false;

    React.Children.forEach(children, child => {
        if (isChildSelected) return;

        if (IsValidElement<NavItemProps>(child, NavItem)) {
            isChildSelected = currentRoute.indexOf(child.props.url) === 0;
        } else if (IsValidElement<NavGroupItemProps>(child, NavGroupItem)) {
            isChildSelected = IsChildSelected(child.props.children, currentRoute);
        }
    });

    return isChildSelected;
};

// Component Definition
interface NavGroupItemProps {
    text: string;
    children?: React.ReactNode;
}

const NavGroupItem: React.FunctionComponent<NavGroupItemProps> = ({
    text,
    children,
}) => {
    const context = React.useContext(NavBarContext);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        if (IsChildSelected(children, context.currentRoute)) {
            setExpanded(true);
        }
    }, [context, children]);

    const toggleExpanded = React.useCallback(() => (
        setExpanded(prevExpanded => !prevExpanded)
    ), []);

    return (
        <div className={`dxpKitNavBarGroupItem ${expanded ? "expanded" : "collapsed"}`}>
            <div className="dxpKitNavBarGroupItemHeading" onClick={toggleExpanded}>
                <div className="dxpKitNavBarGroupItemHeadingIconCntr">
                    <Icon icon={CoreSvgs.ChevronRight} />
                </div>
                {text}
            </div>
            {expanded && (
                children
            )}
        </div>
    );
};

NavGroupItem.displayName = "NavGroupItem";

export default NavGroupItem;
export { NavGroupItemProps };
