// React Components Imports
import * as React from "react";

// Internal Imports
import { MainLayoutMainContainerTOC, MainLayoutCodeSample } from "@technologyone/t1-dxp-component-designsystem";

// Component Style Imports
import Styles from "../tokens.less";

// Props/State Interface
interface PaletteTabProps {}

// Component Definition
class PaletteTab extends React.Component<PaletteTabProps> {
    render() {
        return (
            <MainLayoutMainContainerTOC>
                <div className="tokensPage"></div>
                <h2 id="BorderRadiusPalette">Border Radius</h2>
                <div aria-hidden="true" className="h2 headingSticky">Border Radius</div>
                <div className="ds-tokensPage-section borderRadiusTokens borderRadiusPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <PaletteToken
                            token="borderRadiusSmall"
                            value="1px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusMedium"
                            value="2px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusLarge"
                            value="4px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusNone"
                            value="0"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusCircle"
                            value="50%"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-10"
                            value="1px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-20"
                            value="2px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-40"
                            value="4px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-60"
                            value="6px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-10"
                            value="8px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-30"
                            value="12px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-50"
                            value="16px"
                            style="borderRadius"
                        />
                    </div>
                </div>

                <h2 id="BorderWidthPalette">Border Width</h2>
                <div aria-hidden="true" className="h2 headingSticky">Border Width</div>
                <div className="ds-tokensPage-section borderTokens borderPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <PaletteToken
                            token="borderWidthNone"
                            value="0"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthSmall"
                            value="1px"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthMedium"
                            value="2px"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthLarge"
                            value="4px"
                            style="borderWidth"
                        />

                    </div>
                </div>

                <h2 id="ColoursPalette">Colours</h2>
                <div aria-hidden="true" className="h2 headingSticky">Colours</div>
                <div className="ds-tokensPage-section coloursTokens colorPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="coloursTBW">
                            <div className="ds-tokens-TableCategoryHeading">Transparent, Black & White Colours</div>
                            <PaletteToken
                                token="colorTransparent"
                                value="transparent"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlack"
                                value="#000000"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhite"
                                value="#FFFFFF"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBlackOpac">
                            <div className="ds-tokens-TableCategoryHeading">Black Opacity Colours</div>
                            <PaletteToken
                                token="colorBlackOpac-01"
                                value="rgba(0,0,0,0,0.01)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-10"
                                value="rgba(0,0,0,0,0.1)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-15"
                                value="rgba(0,0,0,0,0.15)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-20"
                                value="rgba(0,0,0,0,0.2)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-25"
                                value="rgba(0,0,0,0,0.25)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-30"
                                value="rgba(0,0,0,0,0.3)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-40"
                                value="rgba(0,0,0,0,0.4)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-50"
                                value="rgba(0,0,0,0,0.5)"
                                style="backgroundColor"
                            />

                        </div>
                        <div className="coloursWhiteOpac">
                            <div className="ds-tokens-TableCategoryHeading">White Opacity Colours</div>
                            <PaletteToken
                                token="colorWhiteOpac-05"
                                value="rgba(0,0,0,0,0.05)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-10"
                                value="rgba(0,0,0,0,0.1)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-15"
                                value="rgba(0,0,0,0,0.15)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-20"
                                value="rgba(0,0,0,0,0.2)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-30"
                                value="rgba(0,0,0,0,0.3)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-40"
                                value="rgba(0,0,0,0,0.4)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-50"
                                value="rgba(0,0,0,0,0.5)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-80"
                                value="rgba(0,0,0,0,0.8)"
                                style="backgroundColor"
                            />

                        </div>

                        <div className="coloursPrimary">
                            <div className="ds-tokens-TableCategoryHeading">Primary Colours</div>
                            <PaletteToken
                                token="colorPrimaryDarkest"
                                value="#23323A"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryDarker"
                                value="#3B4E58"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryDark"
                                value="#5F717C"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimary"
                                value="#7E93A0"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLight"
                                value="#ADC2CD"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLighter"
                                value="#D0DFE7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLighter-50"
                                value="#E7F1F4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLightest"
                                value="#EEF5F7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLightest-50"
                                value="#F6FAFB"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursSecondary">
                            <div className="ds-tokens-TableCategoryHeading">Secondary Colours</div>
                            <PaletteToken
                                token="colorSecondary"
                                value="var(--colorPrimary)"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursGreyCool">
                            <div className="ds-tokens-TableCategoryHeading">Grey Cool Colours</div>
                            <PaletteToken
                                token="colorGreyCoolDarkest"
                                value="#17252C"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolDarker"
                                value="#364349"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolDarkOpac-05"
                                value="rgba(54,67,73,0.05)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolDark"
                                value="#576368"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCool"
                                value="#7b8489"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolLight"
                                value="#A0A8AB"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolLighter"
                                value="#C7CDCF"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolLighter-50"
                                value="#E5E9EC"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolLightest"
                                value="#F0F3F4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyCoolLightest-50"
                                value="#F7F9F9"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursGreyWarm">
                            <div className="ds-tokens-TableCategoryHeading">Grey Warm Colours</div>
                            <PaletteToken
                                token="colorGreyWarmDarkest"
                                value="#222222"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmDarker"
                                value="#404040"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmDarkOpac-05"
                                value="rgba(64,64,64,0.05)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmDark"
                                value="#606060"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarm"
                                value="#828282"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmLight"
                                value="#A6A6A6"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmLighter"
                                value="#CBCBCB"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmLighter-50"
                                value="#E5E5E5"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmLightest"
                                value="#F2F2F2"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreyWarmLightest-50"
                                value="#F8F8F8"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursRed">
                            <div className="ds-tokens-TableCategoryHeading">Red Colours</div>
                            <PaletteToken
                                token="colorRedDarkest"
                                value="#8C1A10"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorRedDark"
                                value="#D41E33"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorRed"
                                value="#F06B7B"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorRedLight"
                                value="#F8ACB5"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorRedLightest"
                                value="#FFE5E9"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBrown">
                            <div className="ds-tokens-TableCategoryHeading">Brown Colours</div>
                            <PaletteToken
                                token="colorBrown"
                                value="#A24B1C"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursOrange">
                            <div className="ds-tokens-TableCategoryHeading">Orange Colours</div>
                            <PaletteToken
                                token="colorOrange"
                                value="#F07F00"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOrangeLightest"
                                value="#FCE5CB"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursYellow">
                            <div className="ds-tokens-TableCategoryHeading">Yellow Colours</div>
                            <PaletteToken
                                token="colorYellowDark"
                                value="#FFB133"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorYellow"
                                value="#FEE46D"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorYellowLight"
                                value="#FDF2C3"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorYellowLightest"
                                value="#FEF8E1"
                                style="backgroundColor"
                            />

                        </div>

                        <div className="coloursGreen">
                            <div className="ds-tokens-TableCategoryHeading">Green Colours</div>
                            <PaletteToken
                                token="colorGreenDarkest"
                                value="#32591A"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreenDark"
                                value="#4B812C"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreenBright"
                                value="#8EDD00"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreen"
                                value="#A1C75D"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreenLight"
                                value="#CBE895"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorGreenLightest"
                                value="#E7FDBE"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursTeal">
                            <div className="ds-tokens-TableCategoryHeading">Teal Colours</div>
                            <PaletteToken
                                token="colorTealDarkest"
                                value="#1F535D"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorTealDark"
                                value="#337E8E"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorTealBright"
                                value="#02DED4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorTeal"
                                value="#7CD6C2"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorTealLight"
                                value="#AFF4DB"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorTealLightest"
                                value="#D6FDEA"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBlue">
                            <div className="ds-tokens-TableCategoryHeading">Blue Colours</div>
                            <PaletteToken
                                token="colorBlueDarkest"
                                value="#00497D"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueDarker"
                                value="#005A9C"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueDark"
                                value="#0070C1"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlue"
                                value="#009DEE"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueLight"
                                value="#33C5FF"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueLighter"
                                value="#8AE6FF"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueLightest"
                                value="#D2F4FE"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlueLightest-50"
                                value="#E8F9FE"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursIndigo">
                            <div className="ds-tokens-TableCategoryHeading">Indigo Colours</div>
                            <PaletteToken
                                token="colorIndigoDarkest"
                                value="#2D3C76"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigoDark"
                                value="#415AB3"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigo"
                                value="#6C86E5"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigoLight"
                                value="#AFBEF8"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigoLighter"
                                value="#C6D1FC"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigoLightest"
                                value="#DFE5F8"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIndigoLightest-50"
                                value="#EEF1FD"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursPurple">
                            <div className="ds-tokens-TableCategoryHeading">Purple Colours</div>
                            <PaletteToken
                                token="colorPurpleDarkest"
                                value="#582A7F"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPurpleDark"
                                value="#7A47A4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPurple"
                                value="#A776D1"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPurpleLight"
                                value="#D5BDEA"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPurpleLightest"
                                value="#EFE7F6"
                                style="backgroundColor"
                            />
                        </div>


                        <div className="coloursDivergentTealRed">
                            <div className="ds-tokens-TableCategoryHeading">Divergent Teal/Red Palette Colours</div>
                            <PaletteToken
                                token="colorDivergentTealRed-10"
                                value="var(--colorTeal)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-20"
                                value="#A5DED0"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-30"
                                value="#C7EDE4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-40"
                                value="#E1F5F0"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-50"
                                value="#FFE6E4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-60"
                                value="#FDCBC7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-70"
                                value="#f49E98"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentTealRed-80"
                                value="#EF7470"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursDivergentIndigoOrange">
                            <div className="ds-tokens-TableCategoryHeading">Divergent Indigo/Orange Palette Colours</div>
                            <PaletteToken
                                token="colorDivergentIndigoOrange-10"
                                value="var(--colorIndigoDark)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-20"
                                value="var(--colorIndigo)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-30"
                                value="var(--colorIndigoLight)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-40"
                                value="var(--colorIndigoLightest)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-50"
                                value="var(--colorIntensityOrange-70)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-60"
                                value="var(--colorIntensityOrange-50)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-70"
                                value="var(--colorIntensityOrange-30)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentIndigoOrange-80"
                                value="var(--colorIntensityOrange-20)"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursDivergentIndigoPurpleYellow">
                            <div className="ds-tokens-TableCategoryHeading">Divergent Purple/Yellow Palette Colours</div>
                            <PaletteToken
                                token="colorDivergentPurpleYellow-10"
                                value="var(--colorPurple)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-20"
                                value="#C49EE1"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-30"
                                value="#E0C8F1"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-40"
                                value="#F3E8FB"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-50"
                                value="#FFF7DE"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-60"
                                value="#FFECB3"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-70"
                                value="#FFE278"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorDivergentPurpleYellow-80"
                                value="#FFD100"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursOmbreIndigoYellow">
                            <div className="ds-tokens-TableCategoryHeading">Ombre Indigo Yellow Colours</div>
                            <PaletteToken
                                token="colorOmbreIndigoYellow-10"
                                value="#445CAD"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-20"
                                value="#7F6ABC"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-30"
                                value="#BC5CA2"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-40"
                                value="#E2638B"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-50"
                                value="#F07471"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-60"
                                value="#FF9960"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreIndigoYellow-70"
                                value="#FFC85E"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursOmbreBlueYellow">
                            <div className="ds-tokens-TableCategoryHeading">Ombre Blue Yellow Colours</div>
                            <PaletteToken
                                token="colorOmbreBlueYellow-10"
                                value="#138DEC"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-20"
                                value="#49B3EA"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-30"
                                value="#54C5D8"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-40"
                                value="#5CD3BB"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-50"
                                value="#87DD9B"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-60"
                                value="#C2E384"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorOmbreBlueYellow-70"
                                value="#FBE47F"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursPaletteMonochromeIndigoDark">
                            <div className="ds-tokens-TableCategoryHeading">Monochrome Indigo Dark Colours</div>
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-10"
                                value="var(--colorIndigoDark)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-20"
                                value="#606EC0"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-30"
                                value="#7B84CC"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-40"
                                value="#959AD9"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-50"
                                value="#AEB0E6"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-60"
                                value="#C6C7F2"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteMonochromeIndigoDark-70"
                                value="#DFDFFF"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursIntensityOrange">
                            <div className="ds-tokens-TableCategoryHeading">Intensity Orange Colours</div>
                            <PaletteToken
                                token="colorIntensityOrange-10"
                                value="#E05E00"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-20"
                                value="#F87E25"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-30"
                                value="#FF9F47"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-40"
                                value="#FFAF65"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-50"
                                value="#FFCA8B"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-60"
                                value="#FFDEB7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorIntensityOrange-70"
                                value="#FFEEDA"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursPaletteRegular">
                            <div className="ds-tokens-TableCategoryHeading">Regular Palette Colours</div>
                            <PaletteToken
                                token="colorPaletteRegular-10"
                                value="var(--colorRed)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-20"
                                value="var(--colorYellow)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-30"
                                value="var(--colorGreen)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-40"
                                value="var(--colorTeal)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-50"
                                value="var(--colorBlue)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-60"
                                value="var(--colorIndigo)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteRegular-70"
                                value="var(--colorPurple)"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursPaletteLighter">
                            <div className="ds-tokens-TableCategoryHeading">Lighter Palette Colours</div>
                            <PaletteToken
                                customClassName="future"
                                token="colorPaletteLighter-10"
                                value="token reserved for future use case"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                customClassName="future"
                                token="colorPaletteLighter-20"
                                value="token reserved for future use case"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                customClassName="future"
                                token="colorPaletteLighter-30"
                                value="token reserved for future use case"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                customClassName="future"
                                token="colorPaletteLighter-40"
                                value="token reserved for future use case"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteLighter-50"
                                value="var(--colorBlueLighter)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPaletteLighter-60"
                                value="var(--colorIndigoLighter)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                customClassName="future"
                                token="colorPaletteLighter-70"
                                value="token reserved for future use case"
                                style="backgroundColor"
                            />
                        </div>
                    </div>
                </div>

                <h2 id="ShadowsPalette">Shadows</h2>
                <div aria-hidden="true" className="h2 headingSticky">Shadows</div>
                <div className="ds-tokensPage-section shadowTokens shadowPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <PaletteToken
                            token="shadowLightest"
                            value="0px 1px 4px rgba(0,0,0,0.1)"
                            style="boxShadow"
                        />
                        <PaletteToken
                            token="shadowLighter"
                            value="0px 1px 4px rgba(0,0,0,0.15)"
                            style="boxShadow"
                        />
                        <PaletteToken
                            token="shadowLight"
                            value="0px 1px 4px rgba(0,0,0,0.2)"
                            style="boxShadow"
                        />
                        <PaletteToken
                            token="shadowHeavy"
                            value="0px 1px 4px rgba(0,0,0,0.2)"
                            style="boxShadow"
                        />

                    </div>
                </div>


                <h2 id="SizesPalette">Sizes</h2>
                <div aria-hidden="true" className="h2 headingSticky">Sizes</div>
                <div className="ds-tokens-Cntr ds-tokensPage-section sizesTokens">
                    <div className="ds-tokens-TableHeadings">
                        <div>Palette Variable</div>
                        <div>Value</div>
                    </div>
                    <div className="sizesWidth">
                        <div className="ds-tokens-TableBody">
                            <div className="ds-tokens-TableCategoryHeading">Width Sizes</div>
                            <PaletteToken
                                token="widthNone"
                                value="0"
                                style="width"
                            />
                            <PaletteToken
                                token="widthHalf"
                                value="50%"
                                style="width"
                            />
                            <PaletteToken
                                token="widthThreeQuarters"
                                value="75%"
                                style="width"
                            />
                            <PaletteToken
                                token="widthFull"
                                value="100%"
                                style="width"
                            />
                            <PaletteToken
                                token="widthTiny-10"
                                value="2px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthTiny-20"
                                value="4px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthTiny-30"
                                value="6px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthTiny-40"
                                value="8px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-10"
                                value="12px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-20"
                                value="16px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-30"
                                value="20px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-40"
                                value="24px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-45"
                                value="28px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-50"
                                value="32px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-55"
                                value="36px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthSmall-60"
                                value="40px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-10"
                                value="44px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-20"
                                value="48px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-22"
                                value="50px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-30"
                                value="56px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-35"
                                value="60px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-40"
                                value="64px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-50"
                                value="80px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-55"
                                value="100px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-60"
                                value="120px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthMedium-70"
                                value="140px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-10"
                                value="160px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-20"
                                value="180px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-25"
                                value="200px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-30"
                                value="220px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-40"
                                value="260px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-45"
                                value="280px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-50"
                                value="320px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-60"
                                value="400px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-70"
                                value="450px"
                                style="width"
                            />
                            <PaletteToken
                                token="widthLarge-90"
                                value="580px"
                                style="width"
                            />

                        </div>
                    </div>
                    <div className="sizesHeight">
                        <div className="ds-tokens-TableCategoryHeading">Height Sizes</div>
                        <PaletteToken
                            token="heightNone"
                            value="0"
                            style="height"
                        />
                        <PaletteToken
                            token="heightFull"
                            value="100%"
                            style="height"
                        />
                        <PaletteToken
                            token="heightTiny-10"
                            value="2px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightTiny-20"
                            value="4px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightTiny-30"
                            value="6px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightTiny-40"
                            value="8px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-10"
                            value="12px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-20"
                            value="16px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-30"
                            value="20px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-40"
                            value="24px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-45"
                            value="28px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-50"
                            value="32px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-55"
                            value="36px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightSmall-60"
                            value="40px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-10"
                            value="44px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-20"
                            value="48px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-30"
                            value="56px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-35"
                            value="60px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-40"
                            value="64px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-50"
                            value="80px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-60"
                            value="120px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightMedium-70"
                            value="140px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightLarge-10"
                            value="160px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightLarge-20"
                            value="180px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightLarge-30"
                            value="220px"
                            style="height"
                        />
                        <PaletteToken
                            token="heightLarge-40"
                            value="260px"
                            style="height"
                        />

                    </div>

                </div>

                <h2 id="SpacingPalette">Spacing</h2>
                <div aria-hidden="true" className="h2 headingSticky">Spacing</div>
                <div className="ds-tokensPage-section spacingTokens spacingPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="spacingNone">
                            <div className="ds-tokens-TableCategoryHeading">No Spacing</div>
                            <PaletteToken
                                token="spacingNone"
                                value="0"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacing-none"
                                value="0px"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTight">
                            <div className="ds-tokens-TableCategoryHeading">Tight Spacing</div>
                            <PaletteToken
                                token="spacingTight-10"
                                value="1px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingTight-20"
                                value="2px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingTight-40"
                                value="4px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingTight-60"
                                value="6px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingTight-80"
                                value="8px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingTight-100"
                                value="10px"
                                style="padding"
                            />
                        </div>
                        <div className="spacingTight">
                            <div className="ds-tokens-TableCategoryHeading">Base Spacing</div>
                            <PaletteToken
                                token="spacingBase-10"
                                value="12px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingBase-20"
                                value="16px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingBase-40"
                                value="20px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingBase-50"
                                value="24px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingBase-100"
                                value="32px"
                                style="padding"
                            />

                        </div>

                        <div className="spacingLoose">
                            <div className="ds-tokens-TableCategoryHeading">Loose Spacing</div>
                            <PaletteToken
                                token="spacingLoose-10"
                                value="40px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingLoose-20"
                                value="48px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingLoose-30"
                                value="60px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingLoose-35"
                                value="70px"
                                style="padding"
                            />
                            <PaletteToken
                                token="spacingLoose-40"
                                value="80px"
                                style="padding"
                            />
                        </div>


                    </div>
                </div>

                <h2 id="TransitionPalette">Transitions</h2>
                <div aria-hidden="true" className="h2 headingSticky">Transitions</div>
                <div className="ds-tokensPage-section transitionTokens transitionPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div></div>
                        <div>Palette Variable</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="transitionDuration">
                            <div className="ds-tokens-TableCategoryHeading">Duration</div>
                            <PaletteToken
                                token="durationInstant"
                                value="0ms"
                                style="transitionDuration"
                                customClassName="transitionDurationInstant"
                            />
                            <PaletteToken
                                token="durationFast-10"
                                value="100ms"
                                style="transitionDuration"
                                customClassName="transitionDurationFast-10"
                            />
                            <PaletteToken
                                token="durationFast-20"
                                value="200ms"
                                style="transitionDuration"
                                customClassName="transitionDurationFast-20"
                            />
                            <PaletteToken
                                token="durationModerate-10"
                                value="300ms"
                                style="transitionDuration"
                                customClassName="transitionDurationModerate-10"
                            />
                            <PaletteToken
                                token="durationModerate-20"
                                value="500ms"
                                style="transitionDuration"
                                customClassName="transitionDurationModerate-20"
                            />
                            <PaletteToken
                                token="durationSlow-10"
                                value="1000ms"
                                style="transitionDuration"
                                customClassName="transitionDurationSlow-10"
                            />
                            <PaletteToken
                                token="durationSlow-20"
                                value="2000ms"
                                style="transitionDuration"
                                customClassName="transitionDurationSlow-20"
                            />
                        </div>

                        <div className="transitionDelay">
                            <div className="ds-tokens-TableCategoryHeading">Delay</div>
                            <PaletteToken
                                token="delayInstant"
                                value="0ms"
                                style="transitionDelay"
                                customClassName="transitionDelayInstant"
                            />
                            <PaletteToken
                                token="delayFast-10"
                                value="100ms"
                                style="transitionDelay"
                                customClassName="transitionDelayFast-10"
                            />
                            <PaletteToken
                                token="delayFast-20"
                                value="200ms"
                                style="transitionDelay"
                                customClassName="transitionDelayFast-20"
                            />
                            <PaletteToken
                                token="delayModerate-10"
                                value="300ms"
                                style="transitionDelay"
                                customClassName="transitionDelayModerate-10"
                            />
                            <PaletteToken
                                token="delayModerate-20"
                                value="500ms"
                                style="transitionDelay"
                                customClassName="transitionDelayModerate-20"
                            />
                            <PaletteToken
                                token="delaySlow-10"
                                value="1000ms"
                                style="transitionDelay"
                                customClassName="transitionDelaySlow-10"
                            />
                            <PaletteToken
                                token="delaySlow-20"
                                value="2000ms"
                                style="transitionDelay"
                                customClassName="transitionDelaySlow-20"
                            />
                        </div>

                        <div className="transitionTimingFunction">
                            <div className="ds-tokens-TableCategoryHeading">Timing Function</div>
                            <PaletteToken
                                token="timingFunctionEaseInOut"
                                value="ease-in-out"
                                style="transitionTimingFunction"
                                customClassName="transitionTimingFunctionEaseInOut"
                            />
                            <PaletteToken
                                token="timingFunctionEaseIn"
                                value="ease-in"
                                style="transitionTimingFunction"
                                customClassName="transitionTimingFunctionEaseIn"
                            />
                            <PaletteToken
                                token="timingFunctionEaseOut"
                                value="ease-out"
                                style="transitionTimingFunction"
                                customClassName="transitionTimingFunctionEaseOut"
                            />
                            <PaletteToken
                                token="timingFunctionLinear"
                                value="linear"
                                style="transitionTimingFunction"
                                customClassName="transitionTimingFunctionLinear"
                            />

                        </div>


                    </div>
                </div>



            </MainLayoutMainContainerTOC>
        );
    }
}

export default PaletteTab;
export { PaletteTabProps };

const PaletteToken: React.FunctionComponent<{token: string, value: string, style: keyof React.CSSProperties, customClassName?: string}> =
({token, value, style, customClassName}) => (
    <div className={`ds-tokens-TableRow ${customClassName}`}>
        <div className="swatchCntr">
            <div className={`swatch`}>
                <div className="swatchInner" style={{[style]: `var(--${token})`}}>
                    <div className="swatchInner2">
                        <div className="swatchInner3">
                            <div className="swatchInner4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="token">
            <span>--</span>{token}
        </div>

        <div className="value">
            {value}
        </div>

    </div>
);
