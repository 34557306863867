import React from "react";
import { useHistory } from "react-router";

const GoogleTagID = "G-QR7862KMHQ";

const useGoogleAnalytics = () => {
    React.useEffect(() => {
        const src = `https://www.googletagmanager.com/gtag/js?id=${GoogleTagID}`;
        if (document.head.querySelector(`[src="${src}"]`)) return undefined;

        const script = document.createElement("script");
        script.async = true;
        script.src = src;

        document.head.insertBefore(script, document.head.querySelector("script"));
        Gtag("js", new Date());
        Gtag("config", GoogleTagID, { send_page_view: true });
        return () => {
            script.remove();
        };
    }, []);
    return { gtag: Gtag };
};

declare global {
    interface Window {
        dataLayer?: unknown[];
        gtag: (...args: unknown[]) => void;
    }
}

declare global {
    interface Window {
        dataLayer?: unknown[];
        gtag: (...args: unknown[]) => void;
    }
}

if (!window.dataLayer) window.dataLayer = [];

export function Gtag(...args: unknown[]): void {
    window.dataLayer?.push(arguments); // eslint-disable-line
    return;
}

export const GoogleAnalyticsProvider: React.FunctionComponent = () => {
    const { gtag } = useGoogleAnalytics();

    const history = useHistory();

    React.useEffect(() => {
        const unregister = history.listen(() => {
            window.setTimeout(() => {
                gtag("event", "page_view", {
                    // eslint-disable-next-line camelcase
                    page_title: document.title,
                    // eslint-disable-next-line camelcase
                    page_location: window.location.href,
                    // eslint-disable-next-line camelcase
                    page_path: window.location.pathname,
                    // eslint-disable-next-line camelcase
                    send_to: GoogleTagID,
                });
            }, 1000);
        });

        return () => {
            unregister();
        };
    }, [gtag, history]);

    return null;
};
