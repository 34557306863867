import { Errors } from "../../common";

import { CategoryIndex as CategoryIndexState } from "../../redux/category";
import { CreateHttpCategoryProvider } from "./categoryProviders";

const DesignSystemBaseUrl = "/dxp-components";
const DesignSystemFileName = "designsystem.js";

export type FetchIndex = () => Promise<{ data?: CategoryIndexState } & { errors?: Errors }>;

export interface CategoryProvider {
    fetchIndex: FetchIndex;
}

const s3CategoryProvider = CreateHttpCategoryProvider({ url: DesignSystemBaseUrl, fileName: DesignSystemFileName });

export const CategoryProvider: CategoryProvider = {
    fetchIndex: async () => {
        const s3Index = await s3CategoryProvider.fetchIndex();

        return {
            data: { ...s3Index.data },
            errors: { ...s3Index.errors },
        };
    },
};
