
export function GroupBy<T>(arr: T[], keySelector: (item: T) => string): { [key: string]: T[] } {
    const groups: { [key: string]: T[] } = { };

    arr.forEach(item => {
        const key = keySelector(item);
        const group = groups[key] ?? [];

        group.push(item);
        groups[key] = group;
    });

    return groups;
}
