
import { ComponentHeader } from "@technologyone/t1-dxp-component-designsystem";

import { Component } from "../../";
import { Errors } from "../../../../common";

type VersionHanlder = (response: ComponentHeader) => { data?: Component } & { errors?: Errors };

const handleV0_1: VersionHanlder = header => {
    try {
        const response: Component = {
            category: header.category,
            displayName: header.displayName,
        };

        return {data: response};
    } catch (e) {
        return { errors: { "DCP-DS-SCP-FIH-01": { error: new Error("DCP-DS-SCP-FIH-01 Unable to parse component header v1 response"), innerException: e} } };
    }
};

export const ConvertComponentHeader: (header: ComponentHeader) => { data?: Component } & { errors?: Errors } =
header => {
    const schema = (header as {__metadata?: {schema?: string}}).__metadata?.schema ?? "undefined";

    const versionHandler = ComponentHeaderVersionHandlers[schema];

    if (!versionHandler) {
        return { errors: { "DCP-DS-SCP-FIH-02": { error: new Error(`DCP-DS-SCP-FIH-02 Unknown component version ${schema}`)} } };
    }

    return versionHandler(header);
};

const ComponentHeaderVersionHandlers: { [schema: string]: VersionHanlder } = {
    0.1: handleV0_1,
    undefined: handleV0_1,
};
