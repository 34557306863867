import { Component, ComponentVersion } from "../../services/components";

import { DesignSystemActionCreatorFactory } from "../common";

const componentActions = DesignSystemActionCreatorFactory("components");

export const FetchComponentIndex = componentActions<{key?: string}>("FetchComponentIndex");
export const FetchComponentIndexAsync = componentActions.async<
    {key: string},
    {components: {[key: string]: {data?: Component} & { versions?: { [key: string]: ComponentVersion | undefined } }}}
>("FetchComponentIndex");

export const FetchComponent = componentActions<{key?: string, name: string, version: string, activeStyle?: { href: string; class?: string; }}>("FetchComponent");
export const FetchComponentAsync = componentActions.async<{key: string, name: string, version: string, activeStyle?: { href: string; class?: string; }}, ComponentVersion>("FetchComponent");
