import { reducerWithInitialState } from "typescript-fsa-reducers";

import { SetVisibleChannels } from "./channelVisibilityActions";
import { DefaultChannelVisibilityState } from "./channelVisibilityState";

export const ChannelVisibilityReducer = reducerWithInitialState(DefaultChannelVisibilityState)
    .case(SetVisibleChannels, (_, {visibleChannels}) => (ArrayUnique([...visibleChannels])));

function ArrayUnique<T>(arr: T[], equality: (a: T, b: T) => boolean = (a, b) => a === b) {
    return arr.filter((a, i, self) => self.findIndex(b => equality(a, b)) === i);
}
