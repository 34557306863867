import React from "react";

import { ComponentVersion, FetchComponent } from "../..";
import { ComponentDefinition0 } from "@technologyone/t1-dxp-component-designsystem";
import { RenderWebComponent } from "@technologyone/t1-dxp-helper-bootstrapper";

const GenerateUrl = (baseUrl: string, component: string, version: string, fileName: string) =>
    `${baseUrl}/${component}/${version}/${fileName}`;

export default function(baseUrl: string, fileName: string): FetchComponent {
    const componentUrl = (name: string, version: string) =>
        new URL(GenerateUrl(baseUrl, name, version, fileName), window.location.href).href;

    return async function(componentName: string, version: string, activeStyle?: { href: string; class?: string; }): Promise<ComponentVersion> {
        try {
            const url = componentUrl(componentName, version);
            await import(/* webpackIgnore: true */ url);
            const webComponent: ComponentDefinition0 = { ...(window as any)?.["t1-dxp-designsystem"]?.currentComponent };

            const pages: { [key: string]: { labelText?: string, component: React.ReactNode } } = {};
            Object.entries(webComponent.pages).forEach(([name, { component, labelText }], i) => {
                pages[name] = {
                    component: <RenderWebComponent component={component} props={{ url, styleData: activeStyle ? JSON.stringify({ url: activeStyle.href, className: activeStyle.class }) : undefined }} webComponentName={`${componentName}-${version}-${name || labelText}`.toLowerCase().replace(/[\s\@\/]/gi, "_")} />,
                    labelText,
                };
            });

            const actualComponent: ComponentVersion = {
                data: { ...webComponent, pages },
            };

            return actualComponent;
        } catch (e) {
            return { errors: { "DCP-DS-HTP-FC-01": {error: new Error(`Failed to fetch component ${componentName}@${version}`), innerException: e }} };
        }
    };
}
