/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import { Errors } from "../../../../common";
import { Category } from "../../../../redux/category";

interface CategoryHeader0_1 {
    displayName: string;
    subcategories: {
        [categoryName: string]: CategoryHeader0_1;
    };
}

export type CategoryHeader = CategoryHeader0_1;

type VersionHandler = (response: CategoryHeader) => { data?: Category } & { errors?: Errors };

const handleV0_1: VersionHandler = header => {
    try {
        const response: Category = {
            displayName: header.displayName,
            subcategories: header.subcategories,
        };

        return { data: response };
    } catch (e) {
        return {
            errors: {
                "DCP-DS-SCP-FIH-01": {
                    error: new Error("DCP-DS-SCP-FIH-01 Unable to parse component header v1 response"),
                    innerException: e,
                },
            },
        };
    }
};

export const ConvertCategoryHeader: (header: CategoryHeader) => { data?: Category } & { errors?: Errors } =
    header => {
        const schema = (header as { __metadata?: { schema?: string } }).__metadata?.schema ?? "undefined";

        const versionHandler = ComponentHeaderVersionHandlers[schema];

        if (!versionHandler) {
            return {
                errors: {
                    "DCP-DS-SCP-FIH-02": { error: new Error(`DCP-DS-SCP-FIH-02 Unknown component version ${schema}`) },
                },
            };
        }

        return versionHandler(header);
    };

const ComponentHeaderVersionHandlers: { [schema: string]: VersionHandler } = {
    0.1: handleV0_1,
    undefined: handleV0_1,
};
