import * as TypeScriptFsa from "typescript-fsa";
import { Article } from "@technologyone/t1-lg-component-article";
import { ServerError } from "../common";

const ArticleActions = TypeScriptFsa.actionCreatorFactory("Article");

const GetArticle = ArticleActions<{knowledgeBaseId: string, location: string}>("GetArticle");
const GetArticleAsync = ArticleActions.async<{knowledgeBaseId: string, location: string}, {article: Article}, ServerError>("GetArticle");

export { GetArticle, GetArticleAsync };
