import { Immutable } from "../../../common";

export interface ChannelComponent {
    component?: { name: string, version: string };
}

export type Channel =
    { data?: { displayName?: string; } }
    & { entries: { [key: string]: ChannelComponent } };

export interface ChannelIndex { [channel: string]: Channel | undefined; }

export const DefaultChannelIndexState: ChannelIndex = {
    beta: { data: { }, entries: { } },
    next: { data: { }, entries: { } },
    latest: { data: { }, entries: { } },
    default: { data: { }, entries: { } },
};

export const MergeChannelIndex: (
    ...indexes: ChannelIndex[]) => Immutable<ChannelIndex> =
(first, second, ...remaining) => {
    if (!first || !second) return first ?? second;

    return MergeChannelIndex({
        ...first,
        ...Object.entries(second)
            .map(([channelName, channel]) => ({ [channelName]: MergeChannels(first[channelName], channel) }))
            .reduce((curr, next) => ({ ...curr, ...next }), {})
    }, ...remaining);
};

export const MergeChannels: (...channel: Array<Channel | undefined>) => Immutable<Channel> | undefined =
(first, second, ...remaining) => {
    if (!first || !second) return first ?? second;

    return MergeChannels({
        data: { ...first.data, ...second.data },
        entries: { ...first.entries, ...second.entries },
    }, ...remaining);
};
