
export { FetchComponentIndex, FetchComponent, FetchComponentAsync, FetchComponentIndexAsync } from "./componentActions";
export { ComponentState, Component, ComponentIndex, MergeComponentIndexes, ComponentVersion } from "./componentState";
export { ComponentReducer } from "./componentReducer";
export {default as ComponentEpic} from "./componentEpic";

import * as SemVer from "semver";

export const GetDefaultComponentVersion: (versions: string[]) => string | undefined =
versions => versions
        .sort(ComponentVersionSortDefault)
        [0];

type ComponentVersionSort = (a: SemVer.SemVer | string | null, b: SemVer.SemVer | string | null) => -1 | 0 | 1;

export const ComponentVersionSortNewestFirst: ComponentVersionSort =
(a, b) => {
    if (a === b) return 0;

    // Reverse the ordering as default sort is low to high
    const [first, second] = [SemVer.parse(b), SemVer.parse(a)];
    if (!first) return -1;
    if (!second) return 1;

    return SemVer.compare(first, second);
};

export const ComponentVersionSortDefault: ComponentVersionSort = ComponentVersionSortNewestFirst;
