
import { ComponentProvider } from "..";

export const CreateLocalComponentProvider: () => ComponentProvider =
() => ({
    async fetchIndex() {
        return { errors: { "DCP-DS-LCP-01": { error: new Error("Not implemented") } } };
    },

    async fetchComponent(name, version, activeStyle) {
        return { errors: { "DCP-DS-LCP-01": { error: new Error("Not implemented") } } };
    }
});
