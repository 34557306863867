interface CodeBlockState {
    [key: string]: string;
}

interface CodeBlockStoreDefinition {
    codeBlocks?: CodeBlockState;
}

const InitialCodeBlockState: CodeBlockState = {};

export default CodeBlockState;
export { InitialCodeBlockState, CodeBlockStoreDefinition };
