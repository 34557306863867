// Third Party Imports
import React from "react";

// TechnologyOne Package Imports
import { MainLayout, TabBarChangeEvent } from "@technologyone/t1-dxp-component-designsystem";

// Internal Imports
import IntroductionTab from "./tabs/introductionTab";
import TokensTab from "./tabs/tokensTab";
import PaletteTab from "./tabs/paletteTab";
import { useTitle } from "../../hooks/useTitle";

// Style Imports
import "./index.less";

// Global Variables
const Tabs = {
    Tokens: { id: "tokensTab", labelText: "Design Tokens" },
    Palette: { id: "paletteTab", labelText: "Palette Token Variables" },
    Introduction: { id: "introductionTab", labelText: "What is A Design Token?" },
};

// Component Definition
interface TokensPageProps {}

const TokensPage: React.FunctionComponent<TokensPageProps> = () => {
    useTitle("Tokens");

    const [selectedTabId, setSelectedTabId] = React.useState<string>(Tabs.Tokens.id);

    const handleTabChange = (changeEvent: TabBarChangeEvent)  => {
        setSelectedTabId(changeEvent.id);
    };

    return (
        <MainLayout
            title="Tokens"
            description="Design tokens are used to store and manage visual design attributes such as colour, typography and size.  They're used in place of hard-coded values in order to ensure consistency and scalability across all dxp product experiences."
            tabs={{
                selectedTabId,
                onChange: handleTabChange,
                tabs: Object.values(Tabs),
            }}
            className="dxpKitDeveloper"
        >
            {selectedTabId === Tabs.Tokens.id
                ? <TokensTab />
                : selectedTabId===Tabs.Palette.id
                ? <PaletteTab />
                : <IntroductionTab />
            }
        </MainLayout>
    );
};

export default TokensPage;
export { TokensPageProps };
