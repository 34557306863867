import { reducerWithInitialState } from "typescript-fsa-reducers";

import { ThemeState, DefaultThemeState } from "./themeState";
import { UpdateThemes, SetActiveTheme } from "./themeActions";

export const ThemeReducer = reducerWithInitialState<ThemeState>(DefaultThemeState)
    .case(UpdateThemes, (state, {themes}) => {
        return {
            ...state,
            index: {
                ...state.index,
                ...themes
            }
        };
    })
    .case(SetActiveTheme, (state, {activeTheme}) => {
        return {
            ...state,
            activeTheme,
        };
    });
