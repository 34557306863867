import { CategoryState, DefaultCategoryState, MergeComponentIndexes } from "./categoryState";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { FetchCategoryIndexAsync } from "./categoryActions";
import { StandardStatuses, MergeStatus } from "../../common";

export const CategoryReducer = reducerWithInitialState<CategoryState>(DefaultCategoryState);

CategoryReducer.case(FetchCategoryIndexAsync.done, (state, { result, params: { key } }) => {
    const newStatus = { type: FetchCategoryIndexAsync.done.type, status: StandardStatuses.Finished };

    const newState: CategoryState = {
        ...state,
        index: MergeComponentIndexes(state.index, result.categories) ?? {},
        status: MergeStatus(state.status, { [key]: [newStatus] }),
    };

    return newState;
});
