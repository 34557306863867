import { ServerError, ServerStatus } from "../common";
import { Article } from "@technologyone/t1-lg-component-article";

interface ArticleState {
    [knowledgeBaseId: string]: KnowledgeBaseArticles;
}

export interface KnowledgeBaseArticles {
    data: Articles;
    status?: ServerStatus;
    error?: ServerError;
}

export interface Articles {
    [articleLocation: string]: ArticleInfo;
}
export interface ArticleInfo {
    data?: Article;
    status?: ServerStatus;
    error?: ServerError;
}

const InitialArticleState: ArticleState = {
};

export default ArticleState;
export { InitialArticleState };
