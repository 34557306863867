// Third Part Imports
import { Epic, combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, mergeMap, map } from "rxjs/operators";
import { Action } from "typescript-fsa";

// Internal Imports
import { FetchCodeBlock, FetchCodeBlockAsync } from "./codeBlockActions";

// Epics
const FetchCodeBlockEpic: Epic<Action<any>> = action$ => (
    action$.pipe(
        filter(FetchCodeBlock.match),
        mergeMap(action => {
            // Fetch the Data.
            return from(
                fetch("/codeBlocks/" + action.payload.codeBlockName + ".txt")
                    .then(response => response.text())
            )
            .pipe(
                map((code: string) => {
                    // Raise an action saying the Fetch has completed.
                    return FetchCodeBlockAsync.done({ result: { codeBlockName: action.payload.codeBlockName, code: code }});
                })
            );
        })
    )
);

export default combineEpics(FetchCodeBlockEpic);
