import { Epic, combineEpics } from "redux-observable";
import { filter, map, mergeMap, throttleTime, tap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { v4 as uuidV4 } from "uuid";

import { CategoryProvider } from "../../services/categories";

import { FetchCategoryIndex, FetchCategoryIndexAsync } from "./categoryActions";
import { concat, of, from } from "rxjs";

const fetchComponentIndexEpic: Epic<AnyAction, AnyAction> = action$ =>
    action$.pipe(
        filter(FetchCategoryIndex.match),
        throttleTime(5_000),
        map(action => ({ ...action, payload: { ...action.payload, key: action.payload.key || uuidV4() } })),
        mergeMap(action =>
            concat(
                of(FetchCategoryIndexAsync.started(action.payload)),
                from(CategoryProvider.fetchIndex()).pipe(
                    tap(({ errors }) => console.assert(!errors, "DCP-DS-CIE-00 Error fetching category index", errors)),
                    filter(result => !!result.data),
                    map(({ data }) =>
                        FetchCategoryIndexAsync.done({ params: action.payload, result: { categories: data ?? {} } })
                    )
                )
            )
        )
    );

export default combineEpics(fetchComponentIndexEpic);
