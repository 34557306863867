import { Errors, Statuses } from "../../common";

interface ThemeBase {
    type: string;
}

export interface LinkedTheme extends ThemeBase {
    type: "dxp-kit.code.one/style/linked";
    href: string;
    class?: string;
}

export type ThemeTypes = LinkedTheme;

export type Theme =
    {
        data?: ThemeTypes & { displayName?: string; };
    }
    & { errors?: Errors }
    & { status?: Statuses };

export interface ThemeIndex { [key: string]: Theme | undefined; }

export type ThemeState =
    { index: ThemeIndex; }
    & { activeTheme?: string }
    & { errors?: Errors; }
    & { status?: Statuses; };

export const DefaultThemeState: ThemeState = {
    index: {},
    activeTheme: "",
};

export const IsModuleTheme = ({type}: {type: string}) => type === "dxp-kit.code.one/style/linked";
