import { Article } from "@technologyone/t1-lg-component-article";
import { GenerateArticleEndpoint, KnowledgeBaseErrors } from "./constants";

export type GetArticleService = (knowledgeBaseId: string, articleLocation: string) => Promise<Article>;
export const GetArticle: GetArticleService = (knowledgeBaseId: string, articleLocation: string) => {
    const article: Promise<Article> = fetch(GenerateArticleEndpoint(knowledgeBaseId, articleLocation)).then((response: Response) => {
        if (response.status === 200) {
            return response.json();
        }
        throw {
            code: KnowledgeBaseErrors.NotFound,
            error: Error(`Getting Article ${articleLocation} for Knowledge Base ${knowledgeBaseId} failed. Status Code ${response.status} - ${response.statusText}`),
        };
    })
        .catch(error => {
            throw {
                code: KnowledgeBaseErrors.NetworkError,
                error,
            };
        });
    return article;
};

export interface ArticleServices {
    getArticleService: GetArticleService;
}

export const ArticleServices = {
    getArticleService: GetArticle,
};
