// Third Party Imports
import * as React from "react";

// TechnologyOne Package Imports
import Divider from "@technologyone/t1-dxp-component-divider";

// Component Definition
interface SeparatorProps {
}

const Separator: React.FunctionComponent<SeparatorProps> = () => (
    <Divider orientation="horizontal" style="dashed" />
);

Separator.displayName = "NavBar-Separator";

export default Separator;
export { SeparatorProps };
