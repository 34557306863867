import { ComponentIndex, ComponentIndexType, IsSchemaType } from "@technologyone/t1-dxp-component-designsystem";
import { ConvertComponentIndex } from "./componentIndex";
import { FetchIndex } from "../..";

export default function(baseUrl: string) {
    const fetchIndex: FetchIndex = async function() {
        const response: Partial<ComponentIndex> = await (await fetch(`${baseUrl}/list.json`, {method: "GET"})).json();

        if (!IsSchemaType<ComponentIndex>(response, ComponentIndexType)) {
            return { errors: { "DCP-DS-SCP-00": {error: new Error("DCP-DS-SCP-00 - Invalid index response shape")} } };
        }

        return ConvertComponentIndex(response, {componentBaseUrl: baseUrl});
    };

    return fetchIndex;
}
