import { actionCreatorFactory } from "typescript-fsa";


const DesignSystemPrefix = ".";
export const DesignSystemActionCreatorFactory = (entity: string) => actionCreatorFactory(`${DesignSystemPrefix}/${entity}`);

export interface ServerError {
    errorCode: string;
    error: string;
}

export { ServiceStatus as ServerStatus } from "@technologyone/t1-dxp-helper-service";
