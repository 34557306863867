// Third Party Imports
import * as React from "react";
import { NavLink } from "react-router-dom";

// Component Definition
interface NavItemProps {
    text: string;
    url: string;
    searchKeywords?: Array<string>;
}

const NavItem: React.FunctionComponent<NavItemProps> = ({
    text,
    url,
}) => (
    <NavLink className="dxpKitNavBarItemLink" to={url} activeClassName="dxpKitNavBarSelectedItem">
        {text}
    </NavLink>
);

NavItem.displayName = "NavItem";

export default NavItem;
export { NavItemProps };
