import { ComponentProvider } from "../..";

import fetchComponent from "./fetchComponent";
import fetchIndex from "./fetchIndex";

export const CreateHttpComponentProvider: (options: {url: string, fileName: string}) => ComponentProvider =
({url, fileName}) => ({

    fetchIndex: fetchIndex(url),

    fetchComponent: fetchComponent(url, fileName),
});
