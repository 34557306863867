import { CombineEntityReducers } from "@technologyone/t1-dxp-helper-redux";

import AppState from "./appState";

import CodeBlockReducer from "../components/asynccodeblock/redux/codeBlockReducer";

import { ComponentReducer } from "./component";
import { ChannelReducer } from "./channels";
import { ThemeReducer } from "./theme";
import { CategoryReducer } from "./category";
import ArticleReducer from "./article/articleReducer";

const AppReducer = CombineEntityReducers<AppState>({
    codeBlocks: CodeBlockReducer,
    component: ComponentReducer,
    category: CategoryReducer,
    channel: ChannelReducer,
    theme: ThemeReducer,
    article: ArticleReducer,
});

export default AppReducer;
