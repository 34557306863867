import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";

import * as ArticleActions from "./articleActions";
import { GetArticle } from "../../services/article/articleServices";

// Epics
const GetArticleEpic: Epic<AnyAction, AnyAction> = action$ => (
    action$.pipe(
        filter(ArticleActions.GetArticle.match),
        mergeMap(action => concat(
            of(ArticleActions.GetArticleAsync.started(action.payload)),
            from(GetArticle(action.payload.knowledgeBaseId, action.payload.location)).pipe(
                map(response => {
                    const completeAction = ArticleActions.GetArticleAsync.done({ params: action.payload, result: { article: response } });
                    return completeAction;
                }),
                catchError(() => {
                    const failedAction = ArticleActions.GetArticleAsync.failed({ params: action.payload, error: { errorCode: "", error: "" } });
                    return of(failedAction);
                })
            )
        )),
    )
);

export default combineEpics(GetArticleEpic);

